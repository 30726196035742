import React from "react"
import Slider from "react-slick";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';


import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

const slickSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  //centerMode: true,
  variableWidth: true,
  arrows: false,
  // customPaging: function(i) {
  //   return (
  //     <span></span>
  //   );
  // },
  responsive: [
    {
      breakpoint: 980,
      settings: {
        variableWidth: false,
        centerMode: false
      }
    }
  ]
};

function ProductListings({ pageListingGroup,listingHeading }){
  const breakpoints = useBreakpoint();


return (
  <section className="content very-light-blue-background">

   <div className="eighty-spacer"></div>
   <div className="content-block">
      <div className="centered-content-container">
         {listingHeading ? (<h3 className="savings-title">{listingHeading}</h3>) : null}
         <div className="sixty-spacer"></div>
      </div>
      </div>

      <Slider className="one-flex-container product-slider htb-difference-mon-slider two-up " {...slickSettings}>
      {pageListingGroup.length
        ? pageListingGroup.map((prod,index) => (
          <div key={index}>
                <a href={`/${prod.productPage.uri}`} key={index}>
                   <div className="third-flex-container third-height-fix">
                      <div className="products-list-block navy-background">
                         <h2 className="savings-txt-colour">{prod.productPage.title}</h2>

                          {prod.productPage.dynamicContent.layouts && prod.productPage.dynamicContent.layouts.map((description,index) => (
                            <>
                            {description.aerPercent ? (
                              <>
                              Earn up to<br/>
                              <span className="fixed-rate-savings-accounts">{description.aerPercent}&#37;</span>
                                <>
                                AER
                                </>
                              </>
                        ) : null}
                       </>
                      ))}

                         {prod.productPage.dynamicContent.layouts && prod.productPage.dynamicContent.layouts.map((description,index) => (
                           <>
                           {description.cardTextForListings ? (
                             <h3 dangerouslySetInnerHTML={{ __html: description.cardTextForListings}}/>
                              ) : null}
                              </>
                         ))}

                      </div>
                      {prod.productPage.dynamicContent.layouts && prod.productPage.dynamicContent.layouts.map((description,index) => (
                        <>
                        {description.cardCtaForListings ? (
                      <div className="absolute-bottom-link-holder">{description.cardCtaForListings}</div>
                    ) : null}
                    </>
                      ))}

                   </div>

</a>
                </div>
        ))
      : null}
      </Slider>
       <div className="eighty-spacer"></div>
  </section>
 )
  }


export default ProductListings
